<template>
<div>
  <div class="flex-row" style="justify-content:space-between;margin-bottom:15px;">
    <h2>文稿编辑</h2>
    <div>
      <el-button type="default" size="medium" @click="back">返回</el-button>
      <el-button type="primary" size="medium" @click="ensure">提交</el-button>
    </div>
  </div>
  <div>
    <label>标题：</label>
    <el-input v-model="title" size="medium" style="width:500px;margin-bottom:15px;" placeholder="请输入文稿标题"></el-input>
  </div>
  <doc-editor :value.sync="html"></doc-editor>
</div>
</template>

<script>
import {saveOrUpdateDocument,getDocumentDetail} from '../../../api/system'
import DocEditor from './doc-editor.vue'
export default {
  data () {
    return {
      title:'',
      originUrl:document.location.origin+'/docpage',
      html:'',
      id:null
    };
  },
  props:['type'],
  components: {
    DocEditor
  },
  computed: {},
  methods: {
    back(){
      this.$router.go(-1)
    },
    ensure(){
      if(!this.title){
        this.$message.warning('请输入文稿标题');
        return;
      }
      let para = {
        title:this.title,
        onlineFlag:1,
        richText:this.html,
        accessUrl:this.originUrl,
        id:this.id
      }
      saveOrUpdateDocument(para).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.$router.go(-1);
        }else{
          this.$message.warning(data.desc);
        }
      })
    },
    getDetail(){
      getDocumentDetail({id:this.id}).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          this.html = data.content.richText;
          this.title = data.content.title;
        }else{
          console.log(data.desc);
        }
      })
    }
  },
  created(){
    let query = this.$route.query;
    if(query && query.id){
      this.id = query.id;
      this.getDetail();
    }else{
      this.id = null;
      this.title = '';
      this.html = '';
    }
  }
}

</script>
<style lang='scss' scoped>
</style>